import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

export function ProjectTextContainer({ children }): any {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  min-width: 250px;
  max-width: 800px;
  margin: 4rem auto;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
   margin: 8rem auto;
`)}
`;