import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Heading } from '../UI/Heading';
import { Text } from '../UI/Text';

type Props = {
  tags: string,
  title: string
};

export function ProjectIntro({ tags, title }: Props) {
  return (
    <HeaderContainer>
      <Text mode="client">{tags}</Text>
      <StyledHeading type="client">{title} </StyledHeading>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  position:relative;
  padding-top: 5rem;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    max-width: 40vw;
`)};
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  display: block;
  margin-top: 1rem;
`;