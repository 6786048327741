import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';

import { ProjectHeader } from '../../components/Projecten/ProjectHeader';
import { ProjectIntro } from '../../components/Projecten/ProjectIntro';
import { ProjectQuote } from '../../components/Projecten/ProjectQuote';
import { ProjectTextContainer } from '../../components/Projecten/ProjectTextContainer';
import { ProjectsCarousel } from '../../components/ProjectsCarousel/ProjectsCarousel';
import { Root } from '../../components/Root';
import { IgnoreMaxWidth } from '../../components/UI/IgnoreMaxWidth';
import { Text } from '../../components/UI/Text';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { getImageByAlt } from '../../utils/getImageByAlt';

export const query = graphql`
  {
    allSanityProject(filter: {Client: {eq: "Samtrans"}}) {
      edges {
        node {
          Gallery {
            Alt
            asset {
              fluid(maxWidth: 2000) {
                aspectRatio
                sizes
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;

const samtrans = ({ data }) => {
  const images = data.allSanityProject.edges[0].node.Gallery;

  const headerImage = getImageByAlt(images, 'samtrans-header');
  const desktopImage = getImageByAlt(images, 'samtrans-desktop');
  const phoneImage = getImageByAlt(images, 'samtrans-phone');
  const logo = getImageByAlt(images, 'samtrans-logo');
  const carousel1 = getImageByAlt(images, 'samtrans-carousel-1');
  const carousel2 = getImageByAlt(images, 'samtrans-carousel-2');
  const carousel3 = getImageByAlt(images, 'samtrans-carousel-3');

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dotsClass: 'slick-dots slick-thumb',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false
        }
      }
    ]
  };

  return (
    <>
      <ProjectHeader image={headerImage.asset.fluid.src} />
      <Root>
        <ProjectIntro tags="#web #branding #website" title="We zijn al vertrokken!" />
        <DesktopImage>
          <StyledImg fluid={desktopImage.asset.fluid} alt={desktopImage.Alt} />
          <StyledPhoneImg fluid={phoneImage.asset.fluid} alt={phoneImage.Alt} />
        </DesktopImage>

        <ProjectTextContainer>
          <Text mode="projectText">
            Voor Samtrans werkten we een duidelijke en informatieve website uit, om klanten snel te kunnen navigeren naar het juiste transportmiddel. 
            Daarnaast ontwikkelden we ook een logo en een volledig nieuw design. Het resultaat mag er zijn!
          </Text>
          <br /><br />
          <Text mode="tag">
            Nieuwsgierig hoe we dat aanpakten? Bekijk het zelf op &nbsp;
            <Link to={'https://samtrans.be'} target="_blank">
              <u>Samtrans.be</u>
            </Link>
          </Text>
        </ProjectTextContainer>

        <BrandingContainer>
          <BrandingImg fluid={logo.asset.fluid} alt={logo.Alt} />
          <PhoneImg fluid={phoneImage.asset.fluid} alt={phoneImage.Alt} />
        </BrandingContainer>

        <ProjectQuote
          title='"Nooit geen zorgen meer over ziekte, verlof of uitval door een probleem met uw wagenpark."'
          tag="Sam & Jens Bogaerts @ Samtrans"
          text="U kan bij ons terecht voor zowel express transport als vaste opdrachten op regelmatige als onregelmatige tijdstippen."
        />

        <StyledSlider {...settings}>
          <ImgCarousel fluid={carousel1.asset.fluid} alt={carousel1.Alt} />
          <ImgCarousel fluid={carousel2.asset.fluid} alt={carousel2.Alt} />
          <ImgCarousel fluid={carousel3.asset.fluid} alt={carousel3.Alt} />
        </StyledSlider>

        <ProjectsCarousel forProject={true} carouselTitle="Er is meer." />
      </Root>
    </>
  );
};

export default samtrans;

const DesktopImage = styled.div`
  position: relative;
  margin-top: 5rem;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
`)}
`;

const StyledImg = styled(Img)`
  display: none;
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display: block;
    width: 100%;
`)}
`;

const StyledPhoneImg = styled(Img)`
  margin: 0 auto;
  
  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display: none;
`)}
`;

const BrandingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #F9C23B;
  ${IgnoreMaxWidth};
`;

const BrandingImg = styled(Img)`
  margin: auto auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
  width: 100%;
  padding: 1rem;
  margin: 8rem 3rem;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    padding: 0;
    width: 30%;
    margin: auto auto;
`)}
`;

const PhoneImg = styled(Img)`
  display: none;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    display: block;
    margin: auto auto;
    width: 20%;
`)}
`;

const StyledSlider = styled(Slider)`
  margin-bottom: 5rem;

.slick-dots li.slick-active button:before {
   color:#F9C23B;
   font-size: 10px;
   opacity: initial;
}
 li button:before {
   color: white;  
   font-size: 10px;
   opacity: initial;
 }

 ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-bottom: 15rem;
`)}
`;

const ImgCarousel = styled(Img)`
  max-width: 100%;
  max-height: 100%;
  height: calc(50vh - 160px);

${forBreakpoint(Breakpoint.TabletPortrait, css`
    padding: 0 10px;
    margin: 0 200px;
    padding: 0 10px;
`)}
${forBreakpoint(Breakpoint.TabletLandscape, css`
    height: calc(50vh - 50px);
    margin: 0 200px;
`)}
 ${forBreakpoint(Breakpoint.Desktop, css`
    margin: 0 250px;
`)}
`;
