import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';


export function ProjectHeader({ image }) {
  return (
    <ImageHeader backgroundImage={image} />
  );
}

const ImageHeader = styled.div<{ backgroundImage }>`
  position: absolute;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(5,26,33,0.5) 0%, rgba(5,26,33,0.6) 50%, rgba(5,26,33,1) 100%), url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  
  ${props => forBreakpoint(Breakpoint.TabletLandscape, css`
    background-image: linear-gradient(180deg, rgba(5,26,33,0.1) 0%, rgba(5,26,33,0.8) 50%, rgba(5,26,33,1) 100%), url(${props.backgroundImage});
  `)};
`;