import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Text } from '../../components/UI/Text';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Heading } from '../UI/Heading';


import { ProjectTextContainer } from './ProjectTextContainer';

type Props = {
  title: string,
  tag: string,
  text: string;
};

export function ProjectQuote({ title, tag, text }: Props) {
  return (
    <ProjectTextContainer>
      <Heading type="projectQuote">{title}</Heading>
      <StyledTag mode="tag" asDiv={true}>— {tag}</StyledTag>
      <Text mode="projectText">
        {text}
      </Text>
    </ProjectTextContainer>
  );
}

const StyledTag = styled(Text)`
  margin-bottom: 2rem; 
  margin-top: 2rem;

  ${forBreakpoint(Breakpoint.TabletPortrait,
  css`
    margin-bottom: 6rem;
`)}
`;